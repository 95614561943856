import { useRouter } from 'vue-router'

import useUserStore from '@/stores/user'
import useAuthStore from '@/stores/auth'
import useNotificationsStore from '@/stores/notifications'
import useTranslationsStore from '@/stores/translations'
import useGravityGatewayStore from '@/stores/gravityGateway'
import usePlatformSocketClientStore from '@/stores/platformSocketClient'
import useStompClientStore from '@/stores/stompClient'
import { ROUTES } from '@/utilities/constants.ts'

const useAuth = () => {
  const router = useRouter()
  const authStore = useAuthStore()
  const userStore = useUserStore()
  const notificationsStore = useNotificationsStore()
  const translationsStore = useTranslationsStore()
  const gravityGatewayStore = useGravityGatewayStore()
  const platformSocketClientStore = usePlatformSocketClientStore()
  const stompClientStore = useStompClientStore()

  const handlePostLogoutActions = async () => {
    if (authStore.authData?.sessionId) {
      stompClientStore.unsubscribeSession(authStore.authData?.sessionId)
    }

    authStore.authData = null
    userStore.userData = null

    platformSocketClientStore.disconnect()
    notificationsStore.addNotification(translationsStore.t('log_out_notification_info'), 'warning')
    gravityGatewayStore.notifyAuthorizationChanged({ user: {}, token: null })
    await router.push({ name: ROUTES.QR_CODE })

    authStore.isCashoutLoading = false
  }

  return {
    handlePostLogoutActions
  }
}

export default useAuth
