const enum ROUTES {
  QR_CODE = 'qrCode',
  OTP = 'otp',
  LOBBY = 'lobby'
}

const enum HEADERS {
  CompanyUUID = 'Http-X-Seven-Club-Uuid',
  Authorization = 'Authorization',
  RequestID = 'X-Request-Id'
}

const enum ERROR_CODES {
  OTP_INCORRECT = 40013,
  OTP_EXPIRED = 40016,
  TERMINAL_DISABLED = 40018,
  DUPLICATE_DONGLE_ID = 40021,
  SESSION_ALREADY_ACTIVE = 40022
}

export { ROUTES, HEADERS, ERROR_CODES }
